import { Fragment, useState } from "react";
import DsoObject from "../DsoObject";
import ListHeader from "../list/ListHeader";
import ListTable from "../list/ListTable";
import LocatieFormFormik from "./LocatieFormFormik";
import LocatieAdd from "./LocatieAdd";

const Locaties = (props) => {
	
	const [selectedLocationId, setSelectedLocationId] = useState()
	const [showAddLocation, setShowAddLocation] = useState(false);
	
	const deleteLocation = () => {
		props.deleteItem(selectedLocationId, DsoObject.location)
	}

	const updateLocation = (values) => {
		props.updateItem(values, DsoObject.location)
	}

	let addLocaties = (locaties) => {
		let locations = props.locations.slice()
		let newLocations = locations.concat(locaties)
		props.setLocations(newLocations)
	}

  const handleRowClick = (row) => {
    setSelectedLocationId(row.id)
		props.setSelectedLocationIdsForViewer([row.id])
    props.setShowProps(true)
  }

	const handleRowHover = (row) => {
    if (row) {
      props.setHooveredLocationIds([row.id])
    } else {
      props.setHooveredLocationIds([])
    }
  }

	const columns = [
		{
			Header: 'Naam',
			accessor: 'naam',
		},
		{
			Header: 'datum ge-upload',
			accessor: 'datum',
		},
		{
			Header: 'Type',
			accessor: 'type',
		},
	]

	return ( 
		!showAddLocation
		?	<Fragment>
				<ListHeader type={DsoObject.location} addButtonHandler={setShowAddLocation} />
				<ListTable
					tableHeader={columns}
					tableData={props.locations.map(location => location?.properties)}
					handleRowClick={handleRowClick}
					handleRowHover={handleRowHover}
				/>
				{ props.showProps && 
					<LocatieFormFormik
						data={props.locations.map(location => location?.properties).find(location => location.id === selectedLocationId)}
						updateLocation={updateLocation}
						deleteLocation={deleteLocation}
						setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
						setShowProps={props.setShowProps}
					/>
				}
			</Fragment> 
		: <LocatieAdd setShowAddLocation={setShowAddLocation} setTempLocations={props.setTempLocations} addLocaties={addLocaties} handleHover={props.setHooveredLocationIds} />
	)
}

export default Locaties;
