
import { MapContainer, TileLayer, GeoJSON} from 'react-leaflet'

const GisViewer = (props) => 
	<MapContainer center={[52.5, 5.0]} zoom={8} scrollWheelZoom={true} style={{height: '920px', width: '100%'}}>
		<TileLayer
			attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
			url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
		/>
		{
			/**
			 * Door aanpassen van de key wordt GeoJSON-Component gerenderd. 
			 * Hier maak een array van de id's om die later als 1 waarde weer te geven.
			 */
		}
		{
			props.selectedLocations ? 
			<GeoJSON 
				key={`selected-${props.selectedLocations.map(location => location.properties.id).join('_')}`} 
				data={props.selectedLocations} 
				style={{fillColor: 'blue', color: 'blue'}}
			/> : 
			null
		}  
		{
			props.hoveredLocations ? 
			<GeoJSON 
				key={`hoover-${props.hoveredLocations.map(location => location.properties.id).join('_')}`} 
				data={props.hoveredLocations} 
				style={{fillColor: 'yellow', color: 'yellow'}} 
			/> : 
			null
		}  
	</MapContainer>

export default GisViewer;
