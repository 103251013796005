
import { Fragment } from "react";

const FormHeader = () => {
	
	return (
		<Fragment>
			<hr/>
			<h3>Eigenschappen</h3>
		</Fragment>
	);
}
	
export default FormHeader;
