
import { Button, Row, Col } from "react-bootstrap";

const FormButtons = ({onSubmit, onCancel, onDelete}) => {

	return (
		<Row>
			<Col md='9' sm="12">
				<Button onClick={onSubmit} style={{marginTop: '10px'}} >Opslaan</Button>
				{' '}
				<Button variant='danger' onClick={onCancel} style={{marginTop: '10px'}} >Annuleren</Button>
			</Col>
			<Col md='3' sm="12">
				<Button variant='danger' onClick={onDelete} style={{marginTop: '10px'}} >Verwijderen</Button>
				{' '}	
			</Col>
		</Row>
	);
}
	
export default FormButtons;
