const defensieterreinen = {
"type": "FeatureCollection",
"name": "defensieterreinen",
"crs": { "type": "name", "properties": { "name": "urn:ogc:def:crs:OGC:1.3:CRS84" } },
"features": [
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.0", "OBJECTID": 1, "naam": "onveilig gebied buiten militair terrein behorende bij schietrange De Vliehors", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 4.921275654951796, 53.141582865835545 ], [ 4.921275657959779, 53.141582864050008 ], [ 4.921275889844989, 53.177959288152486 ], [ 4.92127588539679, 53.177959284540783 ], [ 4.910734356543474, 53.179477691932156 ], [ 4.900463924899052, 53.181562175103274 ], [ 4.890548548580471, 53.184195700826002 ], [ 4.890548544123878, 53.184195698111864 ], [ 4.91846579895167, 53.241836391805151 ], [ 4.944218509255827, 53.248743362485499 ], [ 4.977846226103243, 53.249532113517908 ], [ 5.085240812019272, 53.181992617844216 ], [ 5.085240813571456, 53.181992609761068 ], [ 5.075181583891713, 53.174949908882681 ], [ 5.064154873551517, 53.168451980407234 ], [ 5.052242356662259, 53.162546710656727 ], [ 5.039532118911639, 53.157277600789548 ], [ 5.026118015701974, 53.152683449730617 ], [ 5.012098988931529, 53.14879807600208 ], [ 4.997578342128751, 53.145650070995323 ], [ 4.98266299277814, 53.143262595180964 ], [ 4.967462699776998, 53.141653209850652 ], [ 4.952089271192508, 53.140833752313632 ], [ 4.936655760533895, 53.140810246353865 ], [ 4.921275654951796, 53.141582865835545 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.1", "OBJECTID": 2, "naam": "onveilig gebied buiten militair terrein behorende bij schietbaan Marnewaard", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 6.237379113160933, 53.408826884957904 ], [ 6.236379231603538, 53.410826489826952 ], [ 6.248375957087951, 53.422824426626619 ], [ 6.330356987813089, 53.432824062443011 ], [ 6.333357078703958, 53.418826782730775 ], [ 6.336357189899484, 53.404829488930311 ], [ 6.254375757777547, 53.399828881361032 ], [ 6.237379113160933, 53.408826884957904 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.2", "OBJECTID": 3, "naam": "onveilig gebied buiten militair terrein behorende bij schietterrein Fort Erfprins", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 4.439738542536734, 52.928530760436928 ], [ 4.439738539582339, 52.928530759514786 ], [ 4.435939753634212, 52.944356775974526 ], [ 4.434436860267539, 52.960322478344452 ], [ 4.435243808925913, 52.976306450473587 ], [ 4.438356983496345, 52.992187003283412 ], [ 4.443755155646442, 53.007843104762756 ], [ 4.451399577304501, 53.023155308835946 ], [ 4.461234193325557, 53.038006666578674 ], [ 4.473186011159837, 53.052283635886781 ], [ 4.487165596666572, 53.065876955603819 ], [ 4.503067702606407, 53.078682497005374 ], [ 4.520772041028161, 53.090602079396952 ], [ 4.540144168553331, 53.10154423437718 ], [ 4.561036506209793, 53.111424930607889 ], [ 4.583289457500964, 53.120168231830711 ], [ 4.583289457500964, 53.120168231830711 ], [ 4.737995353088798, 52.962351715442999 ], [ 4.439738542536734, 52.928530760436928 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.3", "OBJECTID": 4, "naam": "onveilig gebied buiten militair terrein behorende bij schietterrein Petten", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 4.436639036235499, 52.74141476951101 ], [ 4.436639036274441, 52.74141476771392 ], [ 4.431499803094177, 52.753623743943805 ], [ 4.428085250550224, 52.766053249248834 ], [ 4.426421466617339, 52.778613663987834 ], [ 4.426521963303387, 52.791214345250282 ], [ 4.428387529796396, 52.80376428470705 ], [ 4.432006185538917, 52.816172763752625 ], [ 4.43735322442253, 52.828350010359323 ], [ 4.44439135186723, 52.840207852181351 ], [ 4.453070913509388, 52.8516603612346 ], [ 4.463330221689906, 52.862624478274419 ], [ 4.475095970465044, 52.873020627259102 ], [ 4.488283742160176, 52.882773301574112 ], [ 4.502798600577733, 52.891811615248869 ], [ 4.518535762261907, 52.900069835710013 ], [ 4.535381361706459, 52.907487867130051 ], [ 4.535381369085156, 52.907487869878828 ], [ 4.671038090093821, 52.783962753155791 ], [ 4.436639036235499, 52.74141476951101 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.4", "OBJECTID": 5, "naam": "onveilig gebied buiten militair terrein behorende bij schietrange De Vliehors", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 4.92083370646267, 53.239972527595114 ], [ 4.810232433392809, 53.245294519468167 ], [ 4.810232433380812, 53.24529452036672 ], [ 4.811419408030959, 53.25105727415832 ], [ 4.813439723082638, 53.256736010814159 ], [ 4.816278311395448, 53.262287469876 ], [ 4.81991385318893, 53.267669347286514 ], [ 4.824318922921337, 53.272840614549352 ], [ 4.829460194631117, 53.277761833285759 ], [ 4.835298689622876, 53.282395460532513 ], [ 4.841790068349263, 53.286706136603087 ], [ 4.848884960700484, 53.290660953580236 ], [ 4.856529349690254, 53.294229714271559 ], [ 4.864664971366891, 53.297385160672725 ], [ 4.873229774213634, 53.300103187282581 ], [ 4.882158382648976, 53.302363023632424 ], [ 4.891382609284421, 53.304147399590136 ], [ 4.900831975411615, 53.305442676987788 ], [ 4.910434261858165, 53.306238952970453 ], [ 4.920116061315216, 53.306530140266226 ], [ 4.929803351405123, 53.306314011823972 ], [ 4.929803352905282, 53.306314011829663 ], [ 4.92083370646267, 53.239972527595114 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.5", "OBJECTID": 6, "naam": "onveilig gebied buiten militair terrein behorende bij schietterrein Breezanddijk (EHD 49)", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 5.266257274241221, 52.894743149326651 ], [ 5.18599483950311, 52.890022078095292 ], [ 5.170718614816922, 52.809756977568917 ], [ 5.12544934209614, 52.811979166981786 ], [ 5.129893632134488, 52.846973692682759 ], [ 5.058798017317873, 52.932238465550483 ], [ 5.208770134180476, 53.017501203975655 ], [ 5.266257274241221, 52.894743149326651 ] ] ] ] } },
{ "type": "Feature", "properties": { "id": "Art_2_37_2_defensie_schietbanen.6", "OBJECTID": 7, "naam": "onveilig gebied buiten militair terrein behorende bij schietbaan Petten", "symbolisatie": "vsht023" }, "geometry": { "type": "MultiPolygon", "coordinates": [ [ [ [ 4.413575050818887, 52.620128595441116 ], [ 4.41357505381162, 52.620128593668042 ], [ 4.389584161826964, 52.63459629631992 ], [ 4.367759899186695, 52.650286643716342 ], [ 4.348271797778851, 52.66707944577518 ], [ 4.331272006726953, 52.684845932193255 ], [ 4.316894091091187, 52.703449709247515 ], [ 4.305251926836303, 52.722747783660218 ], [ 4.296438743497427, 52.742591638236149 ], [ 4.290526314725724, 52.762828356887503 ], [ 4.287564284415647, 52.783301788261362 ], [ 4.287579671480509, 52.803853737486051 ], [ 4.29057652994081, 52.824325184584978 ], [ 4.296535792013464, 52.844557513940572 ], [ 4.305415286307658, 52.864393746672626 ], [ 4.317149936493088, 52.883679770344187 ], [ 4.331652162361159, 52.902265545896832 ], [ 4.348812441834974, 52.920006297851465 ], [ 4.368500084915903, 52.936763660254151 ], [ 4.390564169559822, 52.952406784780329 ], [ 4.414834665275901, 52.966813384670083 ], [ 4.441123726901056, 52.979870725391642 ], [ 4.46922714391834, 52.991476534248328 ], [ 4.498925949915768, 53.001539827326958 ], [ 4.529988152588193, 53.00998165456209 ], [ 4.56217060965305, 53.016735743597856 ], [ 4.562170621538838, 53.016735745477227 ], [ 4.672624639320787, 52.792851326710561 ], [ 4.413575050818887, 52.620128595441116 ] ] ] ] } }
]
}

export default defensieterreinen
