import { Fragment, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";

import kanalen from '../gml/versie1_art2_13_bep_oppwater_kanalen_klein';
import defensieterreinen1a from '../gml/versie1_art2_41_2_militaire_schietbanen';
import geenkanalen from '../gml/versie1a_arr2_12_bep_oppwater_geenkanalen_klein';
import vrijwaringsgebieden from '../gml/versie1b_art2_12_vrijwaringsgebieden_vaarwegen_klein'
import defensieterreinen1c from '../gml/versie1c_art2_41_2_militaire schietbanen'

const GeometrieAdd = (props) => {

	const [showGeoms, setShowGeoms] = useState(false);
	const [geoms, setGeoms] = useState([])
	const [filename, setFilename] = useState("")

	const handleFileSelect = (e) => {
		setFilename(e.target.value)
	}

	const handlePreviewLocations = () => {

		let temp = []
		if (filename.includes('versie1_art2_13_bep_oppwater_kanalen')) {
			temp = kanalen.features
		} else if (filename.includes("versie1_art2_41_2_militaire_schietbanen")) {
			temp = defensieterreinen1a.features
		} else if (filename.includes("versie1a_arr2_12_bep_oppwater_geenkanalen")) {
			temp = geenkanalen.features
		} else if (filename.includes("versie1b_art2_12_vrijwaringsgebieden_vaarwegen")) {
			temp = vrijwaringsgebieden.features
		} else if (filename.includes("versie1c_art2_41_2_militaire schietbanen")) {
			temp = defensieterreinen1c.features
		}

		setGeoms(temp)
		setShowGeoms(true)
		props.setTempLocations(temp)
	}
	const handleSaveLocations = () => {
		setShowGeoms(false)
		props.setShowAddLocation(false)
		props.setTempLocations([])

		let temp = geoms.map(item => {
			item.properties.naam = item.properties.naam || item.properties.name || item.properties.gml_id || item.properties.id
			item.properties.versie = 1.0
			item.properties.type = "vlak"
			item.properties.datum = (new Date()).toLocaleDateString('NL-nl')
			console.log(item)
			return (item)
		})

		props.addLocaties(temp)
	}

	const handleCancel = () => {
		props.setShowAddLocation(false)
		props.setTempLocations([])
	}

	return (
		<Fragment>
			{
				!showGeoms ?
				<Form>
					<Form.Group as={Row} controlId="formFileSm" className="mb-3">
						<Form.Label collumn>Voeg een bestand toe</Form.Label>
					
							<Form.Control type="file" size="sm" onChange={e => handleFileSelect(e)}/>
					</Form.Group>
					<Button type="submit" disabled={filename ? false : true} onClick={handlePreviewLocations} >Toevoegen</Button>
					{' '}
					<Button variant='danger' onClick={handleCancel}>Annuleren</Button>
				</Form>
				:
				<Form>
					{ 
					
					geoms.map(geom => 
						<Form.Group as={Row} onMouseOver={()=> {props.handleHover([geom.properties.id])}} onMouseOut={() => props.handleHover([])}>
							<hr/>
							<Form.Label>Locatie id: {geom.properties.id}</Form.Label>
							<Form.Label column sm="2">Naam</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="naam"
									defaultValue= {geom.properties.naam || geom.properties.name || geom.properties.id}
								/>
							</Col>
						</Form.Group>
					)}
					<hr/>
					<Button onClick={handleSaveLocations}>Opslaan</Button>
					{' '}
					<Button variant='danger' onClick={handleCancel} >Annuleren</Button>
				</Form>
			}
		</Fragment>
	);
}

export default GeometrieAdd;
