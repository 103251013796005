import { Button, Col, Row } from "react-bootstrap";
import Search from "../Search";

const ListHeader = (props) => {

	let handleAddButton = () => {props.addButtonHandler(true)}

	return (
		<Row>
			<Col>
				<Search/>
			</Col>
			<Col>
				<Button variant='success' size="sm" type="button" onClick={handleAddButton}  >Nieuwe {props.type}</Button>
			</Col>
		</Row>
	)
}
	
export default ListHeader;
