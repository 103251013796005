import { useState } from "react";
import { Tabs, Tab} from "react-bootstrap";
import Locaties from "./locaties/Locaties";
import Groepen from "./groepen/Groepen";
import Gios from "./gios/Gios";

const Navigation = (props) => {	

	const [showProps, setShowProps] = useState(false)

	let onTabClick = () => {
		props.setSelectedLocationIdsForViewer([])
		props.setHooveredLocationIds([])
		props.setSelectedGroupIdsForViewer([])
		props.setHooveredLocationIds([])
		setShowProps(false)
	}

	return (
		<Tabs defaultActiveKey="locaties" onSelect={onTabClick}>
			<Tab eventKey="locaties" title="Locaties">
				<Locaties
					locations={props.locations}
					setLocations={props.setLocations}
					updateItem={props.updateItem}
					deleteItem={props.deleteItem}
					
					tempLocations={props.tempLocations}
					setTempLocations={props.setTempLocations}

					setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
					setHooveredLocationIds={props.setHooveredLocationIds}
					
					showProps={showProps}
					setShowProps={setShowProps}
				/> 
				</Tab>
			<Tab eventKey="groepen" title="Groepen">
				<Groepen 
					locations={props.locations.map(location => location?.properties)}
					setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
					setHooveredLocationIds={props.setHooveredLocationIds}

					groups={props.groups}
					newItem={props.newItem}
					updateItem={props.updateItem}
					deleteItem={props.deleteItem}

					setSelectedGroupIdsForViewer={props.setSelectedGroupIdsForViewer}
					setHoveredGroupIds={props.setHoveredGroupIds}

					showProps={showProps}
					setShowProps={setShowProps}
				/>
			</Tab>
			<Tab eventKey="gios" title="GIO's">
				<Gios
					locations={props.locations.map(location => location?.properties)}
					setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
					setHooveredLocationIds={props.setHooveredLocationIds}

					groups={props.groups}
					setSelectedGroupIdsForViewer={props.setSelectedGroupIdsForViewer}
					setHoveredGroupIds={props.setHoveredGroupIds}

					showProps={showProps}
					setShowProps={setShowProps}
				/>
			</Tab>
		</Tabs>
	)
}

export default Navigation;
