
import { Col, Form, Row, } from "react-bootstrap";

const GeometrieProps = ({getFieldProps}) => {
	return (
		<>
			<Form.Group as={Row}>
				<Form.Label column sm="2">Naam</Form.Label>
				<Col>
					<Form.Control 
						type="text"
						name="naam"
						{...getFieldProps('naam')}
					/>
				</Col>
			</Form.Group>
			<Form.Group as={Row}>
				<Form.Label column sm="2">Datum ge-upload</Form.Label>
				<Col>
					<Form.Control 
						type="text"
						name="datum"
						{...getFieldProps('datum')}
						disabled
					/>
				</Col>
			</Form.Group>
			<br />
		</>
	)
}

export default GeometrieProps;
