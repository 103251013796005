import { useState } from "react";
import { Col } from "react-bootstrap";
import DsoObject from "../DsoObject";
import GroepenProps from "./GroepenProps";
import ListHeader from "../list/ListHeader";
import ListTable from "../list/ListTable";

const Groepen = (props) => {

	const [selectedGroupId, setSelectedGroupId] = useState();

	const handleRowClick = (row) => {
		setSelectedGroupId(row.id)
		props.setSelectedGroupIdsForViewer([row.id])
		props.setShowProps(true)
	}

	const handleRowHover = (row) => {
    if (row) {
      props.setHoveredGroupIds([row.id])
    } else {
      props.setHoveredGroupIds([])
    }
  }
	
	const newGroup = (values) => {
		props.newItem(values, DsoObject.group)
	}

	const updateGroup = (values) => {
		props.updateItem(values, DsoObject.group)
	}

	const deleteGroup = () => {
		props.deleteItem(selectedGroupId, DsoObject.group)
	}

	let setShowAddGroep = () => {
		let group = {naam: 'Nieuwe groep', versie:'0.1', locationIds: []}
		newGroup(group)
	}

  const columns = [
		{
			Header: 'Naam',
			accessor: 'naam',
		},
		{
			Header: 'Versie',
			accessor: 'versie',
		},
  ]

	return (
		<Col>
			<ListHeader type={DsoObject.group} addButtonHandler={setShowAddGroep}/>
			<ListTable
				tableHeader={columns}
				tableData={props.groups}
				handleRowClick={handleRowClick}
				handleRowHover={handleRowHover}
			/>
			{ 
				props.showProps && 
				<GroepenProps
					locations={props.locations} 
					setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
					setHooveredLocationId={props.setHooveredLocationIds}

					group={props.groups?.find(group => group.id === selectedGroupId )}
					updateGroup={updateGroup} 
					deleteGroup={deleteGroup}

					setSelectedGroupIdsForViewer={props.setSelectedGroupIdsForViewer}

					setShowProps={props.setShowProps} 
				/>
			}
		</Col>
	)
}
export default Groepen;
