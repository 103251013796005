import { Field, Formik } from "formik";
import { Col, Form, Row, ListGroup, ListGroupItem} from "react-bootstrap";
import FormButtons from "../form/FormButtons";
import FormHeader from "../form/FormHeader";

const GroepenProps = (props) => {

	let groupProps = props.group;

	const handleOnSubmit = (values) => {
		props.updateGroup(values)
		handleOnButtonClick()
	}

	const handleOnDelete = () => {
		props.deleteGroup()
		handleOnButtonClick()
	}

	const handleOnCancel = () => {
		handleOnButtonClick()
	}

	const handleOnButtonClick = () => {
		props.setSelectedLocationIdsForViewer([])
		props.setSelectedGroupIdsForViewer([])
		props.setShowProps(false)
	}

	const handleLocationSelect = (locationIds, id) => {
		if (locationIds) {
			if (locationIds.includes(id)) {
				props.setSelectedLocationIdsForViewer(locationIds.filter(l => l !== id))
			} else {
				let newids = locationIds.slice()
				newids.push(id)
				props.setSelectedLocationIdsForViewer(newids)
			}
		}
		props.setSelectedGroupIdsForViewer([])
	}

	return (
		<Formik
			key={props.group?.id}
			initialValues={groupProps}
			onSubmit={handleOnSubmit}>
			
			{({values, handleSubmit, handleChange, getFieldProps}) => {
				return (
					<Form>
						<FormHeader />
						<Form.Group as={Row}>
							<Form.Label column sm="2">Naam</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="naam"
									{...getFieldProps('naam')}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Versie</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="versie"
									{...getFieldProps('versie')}
								/>
							</Col>
						</Form.Group>
						<br/>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Locaties</Form.Label>
							<Col>
							<ListGroup>
								{props.locations?.map(location => 
									<ListGroupItem 
										onMouseOver={()=> props.setHooveredLocationId([location.id])}
										onMouseOut={()=> props.setHooveredLocationId([])}
									>
										<Field id="locationIds" type="checkbox" key={`location-${location.id}`} name="locationIds" 
											value={location.id} 
											onChange={e => {
												console.log(e)
											handleChange(e);
											handleLocationSelect(values.locationIds, e.target.value);
										}}
										/>
										{` `}{location.naam}
									</ListGroupItem>)}
							</ListGroup>
							</Col>
						</Form.Group>
						<br />
						<FormButtons onSubmit={handleSubmit} onDelete={handleOnDelete} onCancel={handleOnCancel} />
					</Form>
				)
			}}
			
		</Formik>
	)
}

export default GroepenProps;
