
import { Field, Formik } from "formik";
import { Col, Form, Row, ListGroup, ListGroupItem} from "react-bootstrap";
import FormButtons from "../form/FormButtons";
import FormHeader from "../form/FormHeader";

const GioProps = (props) => {

	const normen = ['maximum bouwhoogte', 'minimum goothoogte']
	const eenheden = ['cm', 'm']

	const handleOnSubmit = (values) => {
		props.updateGio(values)
		handleOnButtonClick()
	}

	const handleOnDelete = () => {
		props.deleteGio()
		handleOnButtonClick()
	}

	const handleOnCancel = () => {
		handleOnButtonClick()
	}

	const handleOnButtonClick = () => {
		props.setSelectedLocationIdsForViewer([])
		props.setSelectedGroupIdsForViewer([])
		props.setShowProps(false)
	}

	const handleLocationSelect = (locationIds, id) => {
		if (locationIds.includes(id)) {
			props.setSelectedLocationIdsForViewer(locationIds.filter(l => l !== id))
		} else {
			locationIds.push(id)
			props.setSelectedLocationIdsForViewer(locationIds)
		}
	}

	const handleGroupSelect = (groupIds, id) => {
		if (groupIds) {
			if (groupIds.includes(id)) {
				props.setSelectedGroupIdsForViewer(groupIds.filter(l => l !== id))
			} else {
				let newIds = groupIds.slice()
				newIds.push(id)
				props.setSelectedGroupIdsForViewer(newIds)
			}
		}
	}

	return (
		<Formik
			key={props.gio?.id} 
			initialValues={props.gio}
			onSubmit={handleOnSubmit}
		>
			{({values, handleSubmit, handleChange, getFieldProps}) => {
				return (
					<Form>
						<FormHeader />
						<Form.Group as={Row}>
							<Form.Label column sm="2">Naam</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="naam"
									{...getFieldProps('naam')}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Versie</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="versie"
									{...getFieldProps('versie')}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm="2">akn-id</Form.Label>
							<Col>
								<Form.Control 
									type="text"
									name="aknid"
									{...getFieldProps('aknid')}
								/>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Norm</Form.Label>
							<Col>
								<Form.Control as="select" custom 
									type="text"
									name="norm"
									{...getFieldProps('norm')}>
									<option>Selecteer een norm</option>
									{normen.map(norm => <option>{norm}</option>)}
								</Form.Control>
							</Col>
						</Form.Group>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Eenheid</Form.Label>
							<Col>
								<Form.Control as="select" custom 
									type="text"
									name="eenheid"
									{...getFieldProps('eenheid')}>
									<option>Selecteer een eenheid</option>
									{eenheden.map(eenheid => <option>{eenheid}</option>)}
								</Form.Control>
							</Col>
						</Form.Group>
						<br/>
						<Form.Group as={Row}>
							<Form.Label column sm="2">Locaties</Form.Label>
							<Col>
								<ListGroup>
									<ListGroupItem>
										<Row>
											<Col>
												<Form.Label>Naam</Form.Label>
											</Col>
											<Col xs='4'>
												<Form.Label>Normwaarde</Form.Label>
											</Col>
										</Row>
									</ListGroupItem>
									{props.locations.map(location => {
										return (
											<ListGroupItem 
												onMouseOver={()=> props.setHooveredLocationId([location.id])}
												onMouseOut={()=> props.setHooveredLocationId([])}
											>
												<Row>
													<Col>
														<Field id="locationIds" type="checkbox" key={`location-${location?.id}`} name="locationIds" 
															value={location?.id} 
															onChange={e => {
																handleChange(e);
																handleLocationSelect(values.locationIds, e.target.value);
															}}
														/>
														{` `}{location?.naam}
													</Col>
													<Col xs='4'>
														<Form.Control type="text" size='sm'/>
													</Col>
												</Row>
											</ListGroupItem>
										)
									})}
								</ListGroup>			
							</Col>
							<Form.Label column sm="1">Groepen</Form.Label>
							<Col sm='3'>
								<ListGroup>
									{props.groups.map(group => {
										return (
											<ListGroupItem
												onMouseOver={()=> props.setHoveredGroupIds([group.id])}
												onMouseOut={()=> props.setHoveredGroupIds([])}
											>
												<Field id="groupIds" name="groupIds" type="checkbox" key={`group-${group.id}`} 
													value={group.id}
													onChange={e => {
														handleChange(e)
														handleGroupSelect(values.groupIds, e.target.value)
													}}
												/>
												{` `}{group.naam}
											</ListGroupItem>
										)
									})}
								</ListGroup>			
							</Col>
						</Form.Group>
						<FormButtons onSubmit={handleSubmit} onDelete={handleOnDelete} onCancel={handleOnCancel}/>
					</Form>
				)
			}}
		</Formik>
	)
}
	
export default GioProps;
