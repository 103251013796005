import React from 'react'
import { Table } from 'react-bootstrap'
import { useTable } from 'react-table'

const ListTable = ({tableHeader, tableData, handleRowClick, handleRowHover}) => {

  const columns = React.useMemo(
    () => tableHeader,
    [tableHeader]
  )

  const data = React.useMemo(
    () => tableData,
    [tableData],
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data })

  return (
    <Table size="sm" {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>
                {column.render('Header')}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row)
          return (
            <tr
              onClick={()=>handleRowClick(row.original)}
              onMouseOver={()=> handleRowHover(row.original)}
              onMouseOut={()=> handleRowHover()}
              {...row.getRowProps()}
            >
              {row.cells.map(cell => {
                return (
                  <td {...cell.getCellProps()}>
                    {cell.render('Cell')}
                  </td>
                )}
              )}
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export default ListTable;
