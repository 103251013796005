import 'bootstrap/dist/css/bootstrap.min.css';
import 'leaflet/dist/leaflet.css';

import { useState } from 'react';
import { Button, Col, Container, Row} from 'react-bootstrap';

import './App.css';

import Navigation from './Navigation';
import GisViewer from './GisViewer';

import DsoObject from './DsoObject';

function App() {
  
  const [tempLocations, setTempLocations] = useState([])

  const [locations, setLocations] = useState([])
  const [selectedLocationIdsForViewer, setSelectedLocationIdsForViewer] = useState([])
  const [hooveredLocationIds, setHooveredLocationIds] = useState([])

  const [groups, setGroups] = useState([])
  const [selectedGroupIdsForViewer, setSelectedGroupIdsForViewer] = useState([])
  const [hoveredGroupIds, setHoveredGroupIds] = useState([])

  const updateLocation = (newLocation) => {
    let index = locations.findIndex(location => location.properties.id === newLocation.id)
    let oldLocations = locations.slice() // React diifs on reference. with slice() we create new instance.
    oldLocations[index].properties = newLocation
    setLocations(oldLocations)
  }

  const newGroup = (values) => {
    let id
    if (groups.length === 0) {
      id = "1"
    } else {
      let ids = groups.map(group => group.id)
      id = (Math.max(...ids)+1).toString()
    }
    values.id = id
    let oldGroups = groups.slice()
    let newGroups = oldGroups.concat(values)
    setGroups(newGroups)
  }

  const updateGroup = (values) => {
    let index = groups.findIndex(group => group.id === values.id)
    let oldGroups = groups.slice() // React diifs on reference. with slice() we create new instance.
    oldGroups[index] = values
    setGroups(oldGroups)
  }

  const newItem = (values, type) => {
    if (type === DsoObject.group) {
      newGroup(values)
    }
  }

  const deleteItem = (id, type) => {
    if (type === DsoObject.location) {
      let filteredLocations = locations.filter(location => location.properties.id !== id)
      setLocations(filteredLocations)
    }
    if (type === DsoObject.group) {
      let filteredGroups = groups.filter(group => group.id !== id)
      setGroups(filteredGroups)
    }
  }

  const updateItem = (item, type) => {
    if (type === DsoObject.location) {
      updateLocation(item)
    } else if (type === DsoObject.group) {
      updateGroup(item)
    }
  }

  const calculateLocationsToShow = (locationsIds, groupIds) => {
    // Find locationIds from selected groups, put them in one array, make them unique and make sure we return an Array
    let locationIdsFromGroups = Array.from(new Set(groups.filter(group => groupIds.includes(group.id)).map(group => group.locationIds).flat()))
    let locationsToShow = Array.from(new Set(locationsIds.concat(locationIdsFromGroups)))
    
    return locationsToShow
  }

  return (
    <Container style={{ width: '100%', maxWidth: '2000px'}}>
      <Row>
        <Col md="10"><h1><center>Gio-beheer</center></h1></Col>
        <Col><Button variant='success' size="sm" href="https://od-beheer.idgis.nl" style={{marginTop:"5px"}}>Regelingen Beheer</Button></Col>
        <hr/>
      </Row>
      <Row>
        <Col>
          <Navigation
            tempLocations={tempLocations}
            locations={locations}
            groups={groups}
            
            newItem={newItem}
            updateItem={updateItem}
            deleteItem={deleteItem}
            
            setLocations={setLocations}
            setTempLocations={setTempLocations}
            setSelectedLocationIdsForViewer={setSelectedLocationIdsForViewer}
            setHooveredLocationIds={setHooveredLocationIds}

            setSelectedGroupIdsForViewer={setSelectedGroupIdsForViewer}
            setHoveredGroupIds={setHoveredGroupIds}

          />
        </Col>
        <Col xs="6">
          <GisViewer 
            selectedLocations={locations.filter(location => calculateLocationsToShow(selectedLocationIdsForViewer, selectedGroupIdsForViewer).includes(location.properties.id))} 
            hoveredLocations={(tempLocations.length > 0 ? tempLocations : locations).filter(location => calculateLocationsToShow(hooveredLocationIds, hoveredGroupIds).includes(location.properties.id))}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default App;
