
import { Formik } from "formik";
import { Form, } from "react-bootstrap";
import FormButtons from "../form/FormButtons";
import FormHeader from "../form/FormHeader";
import LocatieFormProps from "./LocatieFormProps";

const GeometrieFormFormik = ({data, updateLocation, deleteLocation, setSelectedLocationIdsForViewer, setShowProps}) => {

	const handleOnSubmit = (values) => {
		updateLocation(values)
		handleOnClick()
	}

	const handleOnDelete = () => {
		deleteLocation()
		handleOnClick()
	}

	const handleOnCancel = () => {
		handleOnClick()
	}

	const handleOnClick = () => {
		setSelectedLocationIdsForViewer([])
		setShowProps(false)
	}

	return (
		<Formik 
			key={data?.id}
			initialValues={data} 
			onSubmit={handleOnSubmit}
		>
			{({handleSubmit, getFieldProps}) => {
				return (
					<Form>
						<FormHeader />
						<LocatieFormProps getFieldProps={getFieldProps}/>
						<br />
						<FormButtons onSubmit={handleSubmit} onDelete={handleOnDelete} onCancel={handleOnCancel} />
					</Form>
				)
			}}
		</Formik>
	);
}

export default GeometrieFormFormik;
