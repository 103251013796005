import { Fragment, useState } from "react";
import DsoObject from "../DsoObject";
import GioProps from "./GioProps";
import ListHeader from "../list/ListHeader";
import ListTable from "../list/ListTable";

const Gios = (props) => {
	const [gios, setGios] = useState([]);
	const [selectedGioId, setSelectedGioId] = useState();
	
	const handleRowClick = (row) => {
		setSelectedGioId(row.id)
		let selectedGio = gios.find(gio => gio.id === row.id)
		let locationIds = selectedGio.locationIds || []
		let groupIds = selectedGio.groupIds || []
				
		props.setSelectedLocationIdsForViewer(locationIds)
		props.setSelectedGroupIdsForViewer(groupIds)

    props.setShowProps(true)
	}

	const handleGioHover = (row) => {
		if (row) {
			props.setHooveredLocationIds(gios.find(gio => gio.id === row.id).locationIds)
			props.setHoveredGroupIds(gios.find(gio => gio.id === row.id).groupIds)
		} else {
			props.setHooveredLocationIds([])
			props.setHoveredGroupIds([])
		}
	}

	const newGio = values => {
		let id
		if (gios.length === 0) {
			id = "1"
			values.id = id
			setGios([values])
		} else {
			let ids = gios.map(gio => gio.id)
			id = (Math.max(...ids)+1).toString()
			values.id = id
			let oldGios = gios.slice()
			let newGios = oldGios.concat(values)
			setGios(newGios)
		}
	}

	const updateGio = (values) => {
		console.log(`update gio: ${values.id}`)
		let index = gios.findIndex(gio => gio.id === values.id)
		let currentGios = gios.slice() // React diifs on reference. with slice() we create new instance.
		currentGios[index] = values
		setGios(currentGios)
	}

	const deleteGio = () => {
		console.log('delete GIO')
		let filteredGios = gios.filter(gio => gio.id !== selectedGioId)
		setGios(filteredGios)
	}

	let setShowAddGIO = () => {
		let gio = {naam: 'Nieuwe gio', versie:'0.1', aknid:'/join/id/regdata/mnre1034', locationIds: [], groupIds: [], norm: '', eenheid: ''}
		newGio(gio)
	}

  const columns = [
    {
      Header: 'Naam',
      accessor: 'naam', // accessor is the "key" in the data
    },
    {
      Header: 'Versie',
      accessor: 'versie',
    },
  ]

	return (
		<Fragment>
			<ListHeader type={DsoObject.gio} addButtonHandler={setShowAddGIO}/>
			<ListTable
				tableHeader={columns}
				tableData={gios}
				handleRowClick={handleRowClick}
				handleRowHover={handleGioHover}
			/>
			{ props.showProps && 
				<GioProps
					locations={props.locations}
					setSelectedLocationIdsForViewer={props.setSelectedLocationIdsForViewer}
					setHooveredLocationId={props.setHooveredLocationIds}
					groups={props.groups}
					setSelectedGroupIdsForViewer={props.setSelectedGroupIdsForViewer}
					setHoveredGroupIds={props.setHoveredGroupIds}
					gio={gios.find(gio => gio.id === selectedGioId)}
					updateGio={updateGio}
					deleteGio={deleteGio}
					setShowProps={props.setShowProps}
				/>
			}
		</Fragment>
	)
}


export default Gios;
